import React, { useRef, useState } from "react";
import {
  VStack,
  Text,
  Switch,
  Flex,
  Box,
  IconButton,
  Checkbox,
  Select,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  CloseIcon,
  CopyIcon,
  DownloadIcon,
  EditIcon,
  LockIcon,
  PlusSquareIcon,
  RepeatClockIcon,
  UnlockIcon,
  UpDownIcon,
} from "@chakra-ui/icons";

interface SidebarProps {
  isPreview: boolean;
  setIsPreview: (isPreview: boolean) => void;
  paintColor: string;
  setPaintColor: (color: string) => void;
  showResetModal: () => void;
  downloadChart: () => void;
  isDrawerOpen?: boolean;
  onClose?: () => void;
  useShortMonths: boolean;
  setUseShortMonths: React.Dispatch<React.SetStateAction<boolean>>;
  useShortWeeks: boolean;
  setUseShortWeeks: React.Dispatch<React.SetStateAction<boolean>>;
  timeframe: string;
  setTimeframe: (value: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  isPreview,
  setIsPreview,
  paintColor,
  setPaintColor,
  showResetModal,
  downloadChart,
  isDrawerOpen = false,
  onClose,
  useShortMonths,
  setUseShortMonths,
  useShortWeeks,
  setUseShortWeeks,
  timeframe,
  setTimeframe,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const timeframeSelectRef = useRef<HTMLSelectElement>(null);
  const templateSelectRef = useRef<HTMLSelectElement>(null);
  const colorPickerRef = useRef<HTMLInputElement>(null);

  const handleTimeframeLabelClick = () => {
    timeframeSelectRef.current?.focus();
  };

  const handleTemplateLabelClick = () => {
    templateSelectRef.current?.focus();
  };

  const handleColorPickerClick = () => {
    if (colorPickerRef.current) {
      colorPickerRef.current.click();
    }
  };

  return (
    <VStack
      width="250px"
      height="100vh"
      padding="20px"
      spacing={4}
      borderRight={isDrawerOpen ? "none" : "1px solid"}
      borderColor="gray.200"
      fontSize="sm"
      align="stretch"
      overflowY="auto"
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize="2xl" mb="4">
          GanttGiant.com
        </Text>
        {isDrawerOpen && onClose && (
          <IconButton
            aria-label="Close sidebar"
            icon={<CloseIcon />}
            onClick={onClose}
            color="gray.600"
            position="absolute"
            size="sm"
            top={5}
            right={5}
          />
        )}
      </Flex>
      <Flex
        cursor="pointer"
        align="center"
        onClick={() => setIsPreview(!isPreview)}
      >
        {isPreview ? (
          <LockIcon mr="2" color="gray.500" />
        ) : (
          <UnlockIcon mr="2" color="gray.500" />
        )}
        <Text mr="10px">{isPreview ? "Locked" : "Unlocked"}</Text>
        <Switch isChecked={isPreview} pointerEvents="none" />
      </Flex>

      <Text cursor="pointer" onClick={showResetModal}>
        <RepeatClockIcon mr="8px" color="gray.500" />
        Reset chart
      </Text>
      <Text cursor="pointer" onClick={downloadChart}>
        <DownloadIcon mr="8px" color="gray.500" />
        Download
      </Text>
      <Box></Box>

      <Flex direction="column">
        <Text mb="8px" cursor="pointer" onClick={handleColorPickerClick}>
          <EditIcon mr="2" color="gray.500" />
          Painting color
        </Text>
        <input
          type="color"
          ref={colorPickerRef}
          value={paintColor}
          onChange={(e) => setPaintColor(e.target.value)}
          style={{
            width: paintColor === "#ffffff" ? "22px" : "25px",
            height: paintColor === "#ffffff" ? "23px" : "30px",
            cursor: "pointer",
            marginLeft: paintColor === "#ffffff" ? "-1px" : "-4px",
            marginTop: paintColor === "#ffffff" ? "5px" : "0px",
            border: paintColor === "#ffffff" ? "1px dotted #ccc" : "none",
            boxSizing: "border-box",
          }}
        />
      </Flex>
      <Box></Box>
      <Flex align="center" gap="2" onClick={handleTimeframeLabelClick}>
        <CalendarIcon mr="2px" color="gray.500" />

        <Select
          ref={timeframeSelectRef}
          size="sm"
          variant="unstyled"
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
          width="85px"
          cursor="pointer"
        >
          <option value="months">Months</option>
          <option value="weeks">Weeks</option>
        </Select>
      </Flex>

      <Flex
        cursor="pointer"
        align="center"
        onClick={() =>
          timeframe === "months"
            ? setUseShortMonths(!useShortMonths)
            : setUseShortWeeks(!useShortWeeks)
        }
      >
        <UpDownIcon mr="2" color="gray.500" />
        <Text mr="10px">
          {timeframe === "months" ? "Short months" : "Short weeks"}
        </Text>
        <Checkbox
          isChecked={timeframe === "months" ? useShortMonths : useShortWeeks}
          pointerEvents="none"
        />
      </Flex>

      <Box></Box>
      <Text cursor="pointer">
        <PlusSquareIcon mr="8px" color="gray.500" />
        Custom timeline
      </Text>

      <Flex align="center" gap="2" onClick={handleTemplateLabelClick}>
        <CopyIcon mr="2px" color="gray.500" />

        <Select
          ref={templateSelectRef}
          size="sm"
          variant="unstyled"
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
          width="105px"
          cursor="pointer"
        >
          <option value="" disabled>
            Templates
          </option>
          <option value="availability">Availability</option>
          <option value="project">Project</option>
        </Select>
      </Flex>
    </VStack>
  );
};

export default Sidebar;
