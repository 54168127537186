import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

interface ResetChartModalInterface {
  isOpen: boolean;
  onClose: () => void;
  onReset: () => void;
}

const ResetChartModal: React.FC<ResetChartModalInterface> = ({
  isOpen,
  onClose,
  onReset,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset Chart</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to reset the chart to its default state?
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onReset}>
            Reset
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ResetChartModal;
