import * as React from "react";
import {
  Box,
  GridItem,
  IconButton,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { DeleteIcon, DragHandleIcon } from "@chakra-ui/icons";
import EditableText from "./EditableText";

const initialMonths = [
  { long: "January", short: "Jan" },
  { long: "February", short: "Feb" },
  { long: "March", short: "Mar" },
  { long: "April", short: "Apr" },
  { long: "May", short: "May" },
  { long: "June", short: "Jun" },
  { long: "July", short: "Jul" },
  { long: "August", short: "Aug" },
  { long: "September", short: "Sep" },
  { long: "October", short: "Oct" },
  { long: "November", short: "Nov" },
  { long: "December", short: "Dec" },
];

const initialPhases = [
  { name: "Phase 1", startMonth: 0, duration: 3 },
  { name: "Phase 2", startMonth: 4, duration: 5 },
  { name: "Phase 3", startMonth: 7, duration: 2 },
];

const defaultBoxColor = "#f5f5f5";

interface GanttChartProps {
  isPreview: boolean;
  paintColor: string;
  resetTrigger: boolean;
  resetCounter: number;
  useShortMonths: boolean;
  useShortWeeks: boolean;
  timeframe: string;
}

const GanttChart: React.FC<GanttChartProps> = ({
  isPreview,
  paintColor,
  resetTrigger,
  resetCounter,
  useShortMonths,
  useShortWeeks,
  timeframe,
}) => {
  const [months, setMonths] = React.useState(initialMonths);
  const [phases, setPhases] = React.useState(initialPhases);
  const [gridWidth, setGridWidth] = React.useState(0);
  const gridRef = React.useRef<HTMLDivElement>(null);

  // Initialize with default color for all boxes
  const initialBoxColors = phases.flatMap(() =>
    months.map(() => defaultBoxColor)
  );

  const [boxColors, setBoxColors] = React.useState(initialBoxColors);

  const paintBox = (phaseIndex: number, monthIndex: number) => {
    const boxIndex = phaseIndex * months.length + monthIndex;
    const currentColor = boxColors[boxIndex];
    const newColor = currentColor === paintColor ? defaultBoxColor : paintColor;
    const updatedBoxColors = [...boxColors];
    updatedBoxColors[boxIndex] = newColor;
    setBoxColors(updatedBoxColors);
  };

  // const handleSaveMonth = (index: number, newText: string) => {
  //   const updatedMonths = [...months];
  //   const updatedMonth = { ...updatedMonths[index], long: newText }; // Assuming updating the 'long' name
  //   updatedMonths[index] = updatedMonth;
  //   setMonths(updatedMonths);
  // };

  const handleSavePhase = (index: number, newName: string) => {
    const updatedPhases = [...phases];
    updatedPhases[index] = { ...updatedPhases[index], name: newName };
    setPhases(updatedPhases);
  };

  const addNewMonth = () => {
    const newMonthIndex = months.length + 1; // Assuming you want a generic new month
    const newMonth = {
      long: `Month ${newMonthIndex}`,
      short: `M${newMonthIndex}`,
    }; // Adjust based on your naming convention
    setMonths([...months, newMonth]);

    const updatedBoxColors: string[] = [];
    phases.forEach((_, phaseIndex) => {
      const startIndexOfPhase = phaseIndex * months.length;
      updatedBoxColors.push(
        ...boxColors.slice(
          startIndexOfPhase,
          startIndexOfPhase + months.length
        ),
        defaultBoxColor
      );
    });

    setBoxColors(updatedBoxColors);
  };

  const addNewPhase = () => {
    const newPhase = {
      name: `Phase ${phases.length + 1}`,
      startMonth: 0,
      duration: 1,
    };
    setPhases([...phases, newPhase]);

    // Append default colors for the new phase's boxes
    const newBoxColors = [
      ...boxColors,
      ...new Array(months.length).fill(defaultBoxColor),
    ];
    setBoxColors(newBoxColors);
  };

  const deletePhase = (phaseIndex: number) => {
    // Filter out the deleted phase from the phases array
    const newPhases = phases.filter((_, index) => index !== phaseIndex);
    setPhases(newPhases);

    // Recalculate box colors excluding the deleted phase
    const newBoxColors: string[] = [];
    phases.forEach((_, index) => {
      if (index !== phaseIndex) {
        const start = index * months.length;
        const end = start + months.length;
        newBoxColors.push(...boxColors.slice(start, end));
      }
    });

    setBoxColors(newBoxColors);
  };

  const deleteMonth = (monthIndex: number) => {
    const newMonths = months.filter((_, index) => index !== monthIndex);
    setMonths(newMonths);

    const newBoxColors = boxColors.filter(
      (_, index) => index % months.length !== monthIndex
    );
    setBoxColors(newBoxColors);
  };

  const generateTimeframeHeaders = () => {
    if (timeframe === "weeks") {
      return Array.from({ length: 12 }, (_, i) =>
        useShortWeeks ? `W${i + 1}` : `Week ${i + 1}`
      );
    } else {
      // Handle months similarly as before
      return months.map((month) => (useShortMonths ? month.short : month.long));
    }
  };

  const headers = generateTimeframeHeaders();

  const handleSaveHeader = (index: number, newText: string) => {
    if (timeframe === "months") {
      const updatedMonths = months.map((month, idx) =>
        index === idx
          ? { ...month, long: newText, short: newText.slice(0, 3) }
          : month
      );
      setMonths(updatedMonths);
    } else if (timeframe === "weeks") {
      // Assuming weeks are represented in a similar structure or you're simply tracking week numbers
      // This is more complex and requires a clear definition of how weeks are stored and edited
      // For demonstration, this is kept simple. Adapt based on your actual week handling logic.
      console.log("Weeks editing not directly supported in this model.");
      // Implement logic as needed based on your application's requirements
    }
  };

  // Effect hook to listen for resetTrigger changes
  React.useEffect(() => {
    setMonths([...initialMonths]);
    setPhases([...initialPhases]);

    // Calculate default colors based on the initial state directly
    const defaultColors = initialPhases.flatMap(() =>
      new Array(initialMonths.length).fill(defaultBoxColor)
    );

    setBoxColors(defaultColors);
  }, [resetTrigger]);

  React.useEffect(() => {
    const newGridWidth = months.length * 80 + (months.length - 1) * 5;
    setGridWidth(newGridWidth);
  }, [months.length]);

  return (
    <div id="ganttChart" style={{ position: "relative" }}>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div
          ref={gridRef}
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${headers.length}, 80px)`,
            gap: "5px",
            marginLeft: "50px",
            width: `${gridWidth}px`,
          }}
        >
          <GridItem />
          {/* Month Headers */}
          {headers.map((header, index) => (
            <GridItem
              key={index}
              justifySelf="stretch"
              position="relative"
              mb="5px"
              style={{
                minWidth: header === "September" ? "90px" : "auto", // Custom width for "September"
              }}
            >
              <EditableText
                text={header}
                onSave={(newText) => handleSaveHeader(index, newText)}
                resetTrigger={resetCounter}
              />
              {!isPreview && (
                <Flex justifyContent="center" mt="5">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<DragHandleIcon />}
                      variant="ghost"
                      size="xs"
                      style={{ transform: "rotate(90deg)" }}
                    />
                    <MenuList>
                      <MenuItem
                        icon={<DeleteIcon color="red.500" />}
                        onClick={() => deleteMonth(index)}
                        isDisabled={months.length <= 1}
                      >
                        Delete month
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              )}
            </GridItem>
          ))}
          {/* Month Skeleton */}
          {!isPreview && (
            <div
              style={{
                marginLeft: "45px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "absolute",
                left: `${months.length * 80 + (months.length - 1) * 5 + 100}px`,
                top: "80px",
              }}
              onClick={addNewMonth}
            >
              {/* Plus-icon and Monthly-skeleton */}
              <Box
                as="span"
                style={{
                  position: "absolute",
                  top: "-30px",
                  left: "35px",
                  color: "gray.500",
                }}
              >
                +
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={`calc(60px * ${phases.length} + (5px * ${phases.length}))`}
                padding="8px"
                border="2px dotted lightgray"
                borderRadius="md"
                width="80px"
              />
            </div>
          )}
          {/* Phases */}
          {phases.map((phase, phaseIndex) => (
            <React.Fragment key={phaseIndex}>
              {/* Phase Name */}
              <GridItem colStart={1} rowStart={phaseIndex + 2} height="100%">
                <Flex
                  height="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  {!isPreview ? (
                    <>
                      <Box style={{ marginLeft: "-80px", flex: 1 }}>
                        {" "}
                        {/* Adjust marginLeft as needed */}
                        <EditableText
                          text={phase.name}
                          onSave={(newName) =>
                            handleSavePhase(phaseIndex, newName)
                          }
                          resetTrigger={resetCounter}
                        />
                      </Box>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<DragHandleIcon />}
                          variant="ghost"
                          size="xs"
                        />
                        <MenuList>
                          <MenuItem
                            icon={<DeleteIcon color="red.500" />}
                            onClick={() => deletePhase(phaseIndex)}
                            isDisabled={phases.length <= 1}
                          >
                            Delete phase
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </>
                  ) : (
                    <Box style={{ marginLeft: "-10px", flex: 1 }}>
                      <Text>{phase.name}</Text>{" "}
                    </Box>
                  )}
                </Flex>
              </GridItem>

              {/* Grid Boxes */}
              {months.map((_, monthIndex) => {
                const boxIndex = phaseIndex * months.length + monthIndex;
                return (
                  <GridItem
                    key={`phase-${phaseIndex}-month-${monthIndex}`}
                    colStart={monthIndex + 2}
                    rowStart={phaseIndex + 2}
                    onClick={() =>
                      !isPreview && paintBox(phaseIndex, monthIndex)
                    }
                  >
                    <Box bg={boxColors[boxIndex]} width="80px" height="60px" />
                  </GridItem>
                );
              })}
            </React.Fragment>
          ))}
          {/* Phase Skeleton */}
          {!isPreview && (
            <GridItem
              colStart={2}
              colSpan={months.length}
              py={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              cursor="pointer"
              onClick={addNewPhase}
              border="2px dotted lightgray"
              borderRadius="md"
              position="relative"
              height="60px"
              top={"5px"}
            >
              <Box
                as="span"
                position="absolute"
                left="-20px"
                top="50%"
                transform="translateY(-50%)"
                color={"gray.500"}
              >
                +
              </Box>
            </GridItem>
          )}
        </div>
      </div>
    </div>
  );
};

export default GanttChart;
