import * as React from "react";
import {
  ChakraProvider,
  Box,
  theme,
  Flex,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";

import GanttChart from "./GanttChart";
import Sidebar from "./Sidebar";
import downloadChart from "./downloadChart";
import ResetChartModal from "./ResetChartModal";
import { HamburgerIcon } from "@chakra-ui/icons";

export const App = () => {
  const [isPreview, setIsPreview] = React.useState(false);
  const [paintColor, setPaintColor] = React.useState("#ffc677");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [resetTrigger, setResetTrigger] = React.useState(false);
  const [resetCounter, setResetCounter] = React.useState(0);
  const [useShortMonths, setUseShortMonths] = React.useState(false);
  const [useShortWeeks, setUseShortWeeks] = React.useState(false);

  const [timeframe, setTimeframe] = React.useState("months"); // Default to months

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showResetModal = () => {
    setIsModalOpen(true);
  };

  const handleResetChart = () => {
    setResetTrigger(!resetTrigger);
    setResetCounter(resetCounter + 1);
    setIsPreview(false);
    setIsModalOpen(false);
  };

  const handleDownloadChart = () => {
    downloadChart("ganttChart");
  };

  return (
    <ChakraProvider theme={theme}>
      <Flex height="100vh" overflow="hidden">
        {/* Mobile menu toggle button */}
        <IconButton
          aria-label="Toggle sidebar"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          display={{ base: "block", md: "none" }}
          position="absolute"
          zIndex="overlay"
          top="1rem"
          left="1rem"
        />

        {/* Sidebar for mobile screens */}
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <Sidebar
              isPreview={isPreview}
              setIsPreview={setIsPreview}
              paintColor={paintColor}
              setPaintColor={setPaintColor}
              showResetModal={() => setIsModalOpen}
              downloadChart={() => downloadChart("ganttChart")}
              isDrawerOpen={isOpen}
              onClose={onClose}
              useShortMonths={useShortMonths}
              setUseShortMonths={setUseShortMonths}
              useShortWeeks={useShortWeeks}
              setUseShortWeeks={setUseShortWeeks}
              timeframe={timeframe}
              setTimeframe={setTimeframe}
            />
          </DrawerContent>
        </Drawer>
        {/* Sidebar  */}
        <Box
          display={{ base: "none", md: "block" }}
          maxWidth="250px"
          width="100%"
        >
          <Sidebar
            isPreview={isPreview}
            setIsPreview={setIsPreview}
            paintColor={paintColor}
            setPaintColor={setPaintColor}
            showResetModal={showResetModal}
            downloadChart={handleDownloadChart}
            isDrawerOpen={isOpen}
            onClose={onClose}
            useShortMonths={useShortMonths}
            setUseShortMonths={setUseShortMonths}
            useShortWeeks={useShortWeeks}
            setUseShortWeeks={setUseShortWeeks}
            timeframe={timeframe}
            setTimeframe={setTimeframe}
          />
        </Box>

        {/* Reset Confirmation Modal */}
        <ResetChartModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onReset={handleResetChart}
        />

        {/* Main content area  */}
        <Flex
          flex="1"
          p="5"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflowY="auto"
        >
          <Box width="100%" maxWidth="960px" marginTop="100px" ml="40px">
            <GanttChart
              isPreview={isPreview}
              paintColor={paintColor}
              resetTrigger={resetTrigger}
              resetCounter={resetCounter}
              useShortMonths={useShortMonths}
              useShortWeeks={useShortWeeks}
              timeframe={timeframe}
            />
          </Box>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
};
