import React, { useState, KeyboardEvent } from "react";
import { Input, Text } from "@chakra-ui/react";

interface EditableTextProps {
  text: string;
  onSave: (newText: string) => void;
  resetTrigger: number;
}

const EditableText: React.FC<EditableTextProps> = ({
  text,
  onSave,
  resetTrigger,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);

  const handleBlur = () => {
    onSave(value);
    setIsEditing(false);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSave(value);
      setIsEditing(false);
    }
  };

  // Reset internal state when resetTrigger changes
  React.useEffect(() => {
    setValue(text);
  }, [text, resetTrigger]);

  return isEditing ? (
    <Input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      autoFocus
    />
  ) : (
    <Text
      onClick={() => setIsEditing(true)}
      cursor="pointer"
      textAlign="center"
      fontWeight="md"
    >
      {text}
    </Text>
  );
};

export default EditableText;
