import html2canvas from "html2canvas";

const downloadChart = async (
  chartElementId: string,
  filename: string = "gantt-chart.png"
) => {
  const chartElement = document.getElementById(chartElementId);
  if (chartElement) {
    const extraSpace = 50;
    const leftTopExtraSpace = 30;

    const canvas = await html2canvas(chartElement, {
      scale: window.devicePixelRatio,
      backgroundColor: "#fff",
      width: chartElement.scrollWidth + extraSpace + leftTopExtraSpace,
      height: chartElement.scrollHeight + extraSpace + leftTopExtraSpace,
      x: -leftTopExtraSpace,
      y: -leftTopExtraSpace,
      scrollX: -window.scrollX - extraSpace / 2 - leftTopExtraSpace,
      scrollY: -window.scrollY - extraSpace / 2 - leftTopExtraSpace,
    });

    const dataUrl = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = filename;
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default downloadChart;
